module.exports = {
  siteTitle: 'mcruzlehardy', // <title>
  manifestName: 'Full time Software Engineer',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-stylish/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Miguel Cruz Le Hardy',
  subHeading: 'Full time Software Engineer',

  // social
  socialLinks: [
    {
      icon: 'icon-social-github',
      name: 'Github',
      url: 'https://github.com/Miguel787',
    },
    {
      icon: 'icon-social-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/miguel-cruzlehardy/',
    },
    {
      icon: 'icon-envelope',
      name: 'E-mail',
      url: 'mailto:contactus@mcruzlehardy.dev',
    },
  ],

  email: 'contactus@mcruzlehardy.dev',
};
